<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Banner Detail</h1>
        </div>
        <div class="title-tabs mt-3">Banner Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6" class="order-1 mb-3">
                <UploadFile
                  textFloat="Cover Image"
                  placeholder="Please Choose File"
                  format="video"
                  :fileName="form.banner.image_url"
                  v-model="form.banner.image_url"
                  name="thumbnail"
                  text="*Please upload only .png, .jpg image and .mp4 less than 150 MB"
                  isRequired
                  :accept="acceptType"
                  v-on:onFileChange="onImageChange"
                  :v="$v.form.banner.image_url"
                  :disabled="isDisable"
                />
                <PreviewBox
                  v-on:delete="deleteImage"
                  :disabled="isDisable"
                  :showPreviewType="showPreviewType"
                  :showPreview="showPreview"
                  :isLoadingImage="isLoadingImage"
                  :ratioType="16"
                />
              </b-col>
              <b-col md="6" class="order-3 order-sm-2">
                <InputText
                  textFloat="Sort Order"
                  placeholder="Sort Order"
                  type="number"
                  name="sortOrder"
                  v-model="form.banner.sortOrder"
                  :disabled="isDisable"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Banner Type <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="banner type"
                  isRequired
                  v-model="form.banner.mobile_redirect_type_id"
                  v-bind:options="typelist"
                  valueField="id"
                  textField="redirect_type_name"
                  :noPleaseSelect="noPleaseSelect"
                  @onDataChange="handleChangeBannerType"
                  :disabled="isDisable"
                  :v="$v.form.banner.mobile_redirect_type_id"
                />
              </b-col>
              <b-col md="6" v-if="form.banner.mobile_redirect_type_id == 5">
                <InputText
                  class="mb-0"
                  textFloat="External URL"
                  placeholder="External URL"
                  type="text"
                  name="externalurl"
                  :disabled="isDisable"
                  v-model="form.banner.redirect_id"
                />
              </b-col>
              <b-col cols="6" v-if="form.banner.mobile_redirect_type_id == 6">
                <InputText
                  class="mb-0"
                  textFloat="Campaign Url (Line)"
                  placeholder="Campaign Url (Line)"
                  type="text"
                  name="campaignform "
                  :disabled="isDisable"
                  v-model="form.banner.redirect_url"
                />
              </b-col>
            </b-row>
            <div
              v-if="
                form.banner.mobile_redirect_type_id == 1 ||
                form.banner.mobile_redirect_type_id == 2 ||
                form.banner.mobile_redirect_type_id == 3 ||
                form.banner.mobile_redirect_type_id == 4
              "
              class="panelborder"
            >
              <div
                class="title-tabs"
                v-if="form.banner.mobile_redirect_type_id == 1"
              >
                Product Detail
              </div>
              <div
                class="title-tabs"
                v-if="form.banner.mobile_redirect_type_id == 2"
              >
                Category
              </div>
              <div
                class="title-tabs"
                v-if="form.banner.mobile_redirect_type_id == 3"
              >
                Subcategory
              </div>
              <div
                class="title-tabs"
                v-if="form.banner.mobile_redirect_type_id == 4"
              >
                Monthly privilege
              </div>
              <b-container class="no-gutters bg-white p-2 p-sm-3">
                <div>
                  <ProductList
                    :productList="productList"
                    :idList="IdList"
                    :rows="rows"
                    :isBusy="isBusy"
                    :disabled="isDisable"
                    v-on:setRedirectID="setRedirectID"
                    @handleSearchRedirectList="handleSearchRedirectList"
                  />
                </div>
              </b-container>
            </div>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.banner.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.banner.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="checkForm(0)" routePath="/banner" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";
import ProductList from "@/components/product/ProductList";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  name: "BannerDetails",
  components: {
    InputText,
    InputSelect,
    UploadFile,
    ProductList,
    OtherLoading,
    PreviewBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      existId: "",
      isEdit: false,
      isLoading: false,
      isLoadingImage: false,
      isDisable: false,
      noPleaseSelect: false,
      isBusy: false,
      coverImgType: 1,
      ratioMatch: false,
      showVideo: "",
      showPreview: "",
      showPreviewType: 0,
      typelist: [],
      productList: [],
      acceptType: "image/jpeg, image/png, video/mp4",
      rows: 0,
      IdList: [],
      filter: {
        search: "",
      },
      form: {
        banner: {
          id: 0,
          image_url: "",
          Base64String: "",
          is_video: 0,
          mobile_redirect_type_id: 0,
          redirect_type_name: "",
          redirect_id: "",
          redirect_url: "",
          sortOrder: 0,
          status: 0,
        },
      },
    };
  },
  validations: {
    form: {
      banner: {
        image_url: { required },
        mobile_redirect_type_id: { required },
        status: { required },
      },
    },
  },
  created: async function () {
    if (this.id !== "0") {
      await this.getDatas();
    }
    await this.GetRedirectType();
  },
  methods: {
    getDatas: async function () {
      this.isLoading = true;
      await this.$store.dispatch("ActionGetBannerByID", this.id);
      var data = await this.$store.state.moduleConnectAPI.stateGetBannerByID;
      if (data.result == 1) {
        this.form.banner = data.detail;
        this.showPreview = this.form.banner.image_url;
        this.IdList = [data.detail.redirect_id];
        this.handleChangeBannerType(this.form.banner.mobile_redirect_type_id);
        if (this.form.banner.is_video === 1) {
          this.$v.form.$reset();
          this.showPreviewType = 1;
        } else {
          this.showPreviewType = 0;
        }
        this.isLoading = false;
      }
    },
    async GetRedirectType() {
      await this.$store.dispatch("ActionGetRedirectType", this.id);
      var data = await this.$store.state.moduleConnectAPI.stateGetRedirectType;
      if (data.result == 1) {
        this.typelist = data.detail;
      }
    },
    handleChangeBannerType(id) {
      this.form.banner.mobile_redirect_type_id = id;
      this.GetRedirect();
    },
    setRedirectID: async function (id) {
      this.form.banner.redirect_id = id;
    },
    GetRedirect: async function () {
      this.isBusy = true;
      var setData = {
        id: this.form.banner.mobile_redirect_type_id,
        name: this.filter.search,
      };
      await this.$store.dispatch("ActionGetRedirect", setData);
      var data = await this.$store.state.moduleConnectAPI.stateGetRedirect;
      if (data.result == 1) {
        this.productList = data.detail;
        this.rows = data.detail.length;
        this.isBusy = false;
      }
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        if (val.type === "video/mp4") {
          var url = await this.saveImagetoDb(reader.result, true);
          this.showPreviewType = 1;
          this.isLoadingImage = false;
          this.isDisable = false;
          this.showPreview = url;
          this.form.banner.image_url = url;
          this.form.banner.is_video = 1;
        } else {
          let img = new Image();
          img.onload = () => {
            // var width = parseInt(img.width) / 16;
            // var height = parseInt(img.height) / 9;
            // if (parseInt(width) !== parseInt(height)) {
            //   this.$swal("Please upload image with ratio 16:9 !", {
            //     icon: "info",
            //   });
            //   this.ratioMatch = false;
            //   this.isLoadingImage = false;
            //   this.isDisable = false;
            //   this.deleteImage();
            // } else {
            this.ratioMatch = true;
            this.setUpdataImage(reader.result);
            // }
          };
          img.src = await reader.result;
        }
      };
    },
    async setUpdataImage(base64) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);
        this.showPreviewType = 0;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = url;
        this.form.banner.image_url = url;
        this.form.banner.is_video = 0;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.banner.image_url = "";
      this.showPreview = null;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.isLoading = true;
      let data = null;

      this.form.banner.sortOrder = parseInt(this.form.banner.sortOrder);
      this.form.banner.level_id = 1;
      if (this.id !== "0") {
        await this.$store.dispatch("ActionUpdateBanner", this.form.banner);
        data = await this.$store.state.moduleConnectAPI.stateUpdateBanner;
        if (data.result == 1) {
          this.successAlert().then(() => {
            this.$router.push("/banner");
          });
        }
      } else {
        await this.$store.dispatch("ActionCreateBanner", this.form.banner);
        data = await this.$store.state.moduleConnectAPI.stateCreateBanner;
        if (data.result == 1) {
          this.successAlert().then(() => {
            this.$router.push("/banner");
          });
        }
      }
      this.isLoading = false;
      this.isDisable = false;
    },
    handleSearchRedirectList(searchName) {
      this.filter.search = searchName;
      this.GetRedirect();
    },
  },
};
</script>

<style scoped>
.preview-box-video::before {
  width: 100%;
  padding-top: 56.25%;
}
.preview-box-image {
  width: 100%;
  padding-bottom: 49.385%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}

.panelborder {
  border: 1px solid #d8dbe0;
}
</style>
